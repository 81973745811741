import trackEvent from '../track-event';
import { EVENT_NAME } from '../track-event/track-event.types';
import { CLICK_LOCATION } from './register-partner-click.types';

const registerPartnerClick = (
  location?: CLICK_LOCATION,
  partnerId?: string,
) => {
  trackEvent({
    event: EVENT_NAME.PARTNER_TILE_CLICK,
    location,
    retailerSlug: partnerId,
  });
};

export default registerPartnerClick;
