import { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Mousewheel,
  SwiperOptions,
} from 'swiper';
import classNames from 'classnames';
import Button from '@/components/inputs/button';
import {
  SIZE,
  VARIANT as BUTTON_VARIANT,
} from '@/components/inputs/button/button.types';
import styles from './carousel.module.scss';
import {
  CarouselProperties,
  MAX_SCREEN_SIZE,
  SwiperExtension,
  VARIANT,
} from './carousel.types';
import useCarouselNavigation from '../../../hooks/use-carousel-navigation';
import {
  updateOffsetsForBannersCarousel,
  updateSwiperOffsets,
} from './carousel.utils';
import { VARIANT as ICON_VARIANT } from '../icon/icon.types';

const variants: Record<VARIANT, SwiperOptions> = {
  [VARIANT.Buttons]: {
    freeMode: true,
    freeModeSticky: true,
    centerInsufficientSlides: true,
    centeredSlides: true,
    centeredSlidesBounds: true,
    slidesPerView: 3.5,
    spaceBetween: 16,
    pagination: false,
    scrollbar: {
      hide: false,
      draggable: true,
    },
    mousewheel: {
      forceToAxis: true,
    },
    breakpoints: {
      600: {
        slidesPerView: 4.5,
      },
      980: {
        slidesPerView: 10,
      },
    },
  },
  [VARIANT.Multi]: {
    slidesPerView: 3,
    spaceBetween: 24,
    pagination: false,
    scrollbar: {
      hide: false,
      draggable: true,
    },
    mousewheel: {
      forceToAxis: true,
    },
    breakpoints: {
      600: {
        slidesPerView: 4,
      },
      980: {
        slidesPerView: 6.3,
      },
    },
  },
  [VARIANT.MultiPartners]: {
    slidesPerView: 1.25,
    spaceBetween: 24,
    slidesOffsetBefore: 16,
    slidesOffsetAfter: 16,
    edgeSwipeDetection: 'prevent',
    scrollbar: false,
    mousewheel: {
      forceToAxis: true,
    },
    pagination: false,
    breakpoints: {
      600: {
        slidesPerView: 2.25,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
      },
      980: {
        slidesPerView: 3.5,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
      },
      1272: {
        slidesPerView: 4.5,
      },
      1600: {
        slidesPerView: 5.5,
      },
    },
  },
  [VARIANT.TwoComponents]: {
    slidesPerView: 1.2,
    spaceBetween: 24,
    slidesOffsetBefore: 16,
    slidesOffsetAfter: 16,
    edgeSwipeDetection: 'prevent',
    scrollbar: false,
    mousewheel: {
      forceToAxis: true,
    },
    pagination: false,
    breakpoints: {
      320: {
        slidesPerView: 1.2,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
      },
      760: {
        slidesPerView: 2.3,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
      },
      1272: {
        slidesPerView: 2.3,
      },
      1600: {
        slidesPerView: 3.3,
      },
    },
  },
  [VARIANT.ThreeComponents]: {
    slidesPerView: 1.2,
    spaceBetween: 24,
    slidesOffsetBefore: 16,
    slidesOffsetAfter: 16,
    edgeSwipeDetection: 'prevent',
    scrollbar: false,
    mousewheel: {
      forceToAxis: true,
    },
    pagination: false,
    breakpoints: {
      320: {
        slidesPerView: 1.2,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
      },
      760: {
        slidesPerView: 2.3,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
      },
      1272: {
        slidesPerView: 3.5,
      },
      1600: {
        slidesPerView: 4.3,
      },
    },
  },
  [VARIANT.FourComponents]: {
    slidesPerView: 1.2,
    spaceBetween: 24,
    slidesOffsetBefore: 16,
    slidesOffsetAfter: 16,
    edgeSwipeDetection: 'prevent',
    scrollbar: false,
    mousewheel: {
      forceToAxis: true,
    },
    pagination: false,
    breakpoints: {
      320: {
        slidesPerView: 1.2,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
      },
      760: {
        slidesPerView: 2.3,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
      },
      1272: {
        slidesPerView: 4.5,
      },
      1600: {
        slidesPerView: 5.5,
      },
    },
  },
  [VARIANT.Single]: {
    slidesPerView: 1,
    spaceBetween: 24,
    pagination: false,
    autoplay: {
      disableOnInteraction: false,
      delay: 6000,
    },
    loop: true,
  },
  [VARIANT.Banners]: {
    slidesPerView: 1.2,
    spaceBetween: 16,
    slidesOffsetBefore: 24,
    slidesOffsetAfter: 24,
    edgeSwipeDetection: 'prevent',
    pagination: false,
    allowTouchMove: true,
    breakpoints: {
      568: {
        slidesPerView: 1.1,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 24,
      },
      760: {
        slidesPerView: 1.1,
        spaceBetween: 32,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
        centeredSlides: true,
        centeredSlidesBounds: true,
      },
      984: {
        slidesPerView: 1.4,
        spaceBetween: 32,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
        centeredSlides: true,
        centeredSlidesBounds: true,
      },
      1272: {
        slidesPerView: 1.8,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
      },
      1300: {
        slidesPerView: 1.8,
      },
    },
  },
};

SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay, Mousewheel]);

const Carousel = ({
  variant,
  children,
  withScrollbar,
  className = '',
  autoPlay = false,
}: CarouselProperties) => {
  const [activeSnapIndex, setActiveSnapIndex] = useState(0);
  const [snapGrid, setSnapGrid] = useState([]);
  const [autoPlayRunning, setAutoPlayRunning] = useState(autoPlay);
  const variantClass = styles[`carousel--${variant}`];
  const isVariantBanner = variant === VARIANT.Banners;

  const { reference, handlePrevious, handleNext, handlePlay, handleStop } =
    useCarouselNavigation();
  const handleResize = useCallback(() => {
    if (
      variant === VARIANT.MultiPartners ||
      variant === VARIANT.TwoComponents ||
      variant === VARIANT.ThreeComponents ||
      variant === VARIANT.FourComponents
    ) {
      updateSwiperOffsets(reference, MAX_SCREEN_SIZE.VALUE);
    } else if (isVariantBanner) {
      updateOffsetsForBannersCarousel(
        reference,
        variant,
        MAX_SCREEN_SIZE.VALUE,
      );
    }
  }, [variant, isVariantBanner, reference]);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reference,
    variant,
    updateOffsetsForBannersCarousel,
    updateSwiperOffsets,
  ]);

  const scroll = withScrollbar
    ? {
        scrollbar: {
          hide: false,
          draggable: true,
        },
      }
    : undefined;

  const isAutoPlay = autoPlay
    ? {
        autoplay: {
          disableOnInteraction: false,
          delay: 6000,
        },
      }
    : {
        autoplay: false,
      };

  const flattenedChildren = Array.isArray(children)
    ? children.flat()
    : children;
  const enabled =
    Array.isArray(flattenedChildren) && flattenedChildren.length > 1;

  const snapGridLength = snapGrid.length;
  const isVariantButtons = variant === VARIANT.Buttons;
  const addMaxButtonsToCarousel = isVariantButtons
    ? {
        ...variants[VARIANT.Buttons],
        breakpoints: {
          ...variants[VARIANT.Buttons].breakpoints,
          980: {
            slidesPerView: Array.isArray(flattenedChildren)
              ? flattenedChildren.length
              : 10,
          },
        },
      }
    : {};

  return (
    <div
      className={classNames(
        styles['carousel-wrapper'],
        styles[`carousel-wrapper--${variant}`],
      )}
    >
      <Swiper
        {...variants[variant]}
        {...addMaxButtonsToCarousel}
        {...isAutoPlay}
        {...scroll}
        navigation={enabled}
        enabled={enabled}
        onInit={(core: SwiperCore) => {
          reference.current = core.el;
        }}
        onSlideChangeTransitionEnd={() =>
          updateOffsetsForBannersCarousel(
            reference,
            variant,
            MAX_SCREEN_SIZE.VALUE,
          )
        }
        onSnapGridLengthChange={current => {
          setSnapGrid((current as SwiperExtension)?.snapGrid);
        }}
        onSnapIndexChange={current => {
          setActiveSnapIndex((current as SwiperExtension)?.snapIndex);
        }}
        onAutoplayStart={() => {
          setAutoPlayRunning(true);
        }}
        onAutoplayStop={() => {
          setAutoPlayRunning(false);
        }}
        className={`${styles.carousel}  ${variantClass} carousel--multi ${className}`}
      >
        {flattenedChildren}
      </Swiper>

      {enabled && variant !== VARIANT.Buttons && (
        <div
          className={classNames(`${styles['carousel-controls__wrapper']}`, {
            [styles['carousel-controls__wrapper--variant-banners']]:
              isVariantBanner,
          })}
        >
          <div
            className={classNames(
              `${styles['carousel-controls__play-pause']}`,
              {
                [styles['carousel-controls__play-pause--variant-banners']]:
                  isVariantBanner,
              },
            )}
          >
            <Button
              variant={BUTTON_VARIANT.Unstyled}
              onClick={handlePrevious}
              onKeyDown={handlePrevious}
              size={SIZE.Large}
              icon={ICON_VARIANT.ChevronLeft}
              disabled={activeSnapIndex === 0}
              aria-label="Previous Slide"
            />

            {autoPlay &&
              (autoPlayRunning ? (
                <Button
                  onClick={handleStop}
                  onKeyDown={handleStop}
                  size={SIZE.Large}
                  variant={BUTTON_VARIANT.Unstyled}
                  icon={ICON_VARIANT.Pause}
                  data-testid="pause-button-id"
                  aria-label="Stop Slide"
                />
              ) : (
                <Button
                  onClick={handlePlay}
                  onKeyDown={handlePlay}
                  size={SIZE.Large}
                  variant={BUTTON_VARIANT.Unstyled}
                  icon={ICON_VARIANT.Play}
                  data-testid="play-button-id"
                  aria-label="Play Slide"
                />
              ))}

            <Button
              variant={BUTTON_VARIANT.Unstyled}
              onClick={handleNext}
              onKeyDown={handleNext}
              icon={ICON_VARIANT.ChevronRight}
              size={SIZE.Large}
              disabled={activeSnapIndex + 1 === snapGridLength}
              aria-label="Next Slide"
            />
          </div>

          <div
            className={classNames(
              `${styles['carousel-controls__loading-bars']}`,
              {
                [styles['carousel-controls__loading-bars--variant-banners']]:
                  isVariantBanner,
              },
            )}
          >
            {enabled &&
              snapGrid?.map((key, index) => (
                <div
                  key={key}
                  className={`${styles['carousel-controls__loading-bars__bar']}`}
                >
                  <div
                    className={classNames(styles.selector__button, {
                      [styles['carousel-controls__loading-bars__bar--active']]:
                        index === activeSnapIndex &&
                        autoPlay &&
                        autoPlayRunning,
                      [styles['carousel-controls__loading-bars__bar--touched']]:
                        index <= activeSnapIndex,
                    })}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

Carousel.Item = SwiperSlide;

export default Carousel;
