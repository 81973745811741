import { CarouselReference, VARIANT } from './carousel.types';

export const updateOffsetsForBannersCarousel = (
  reference: CarouselReference,
  variant: string,
  MAX_SCREEN_SIZE: number,
) => {
  if (!reference.current?.swiper) return;

  const { innerWidth } = window;
  const { activeIndex, slides, params, currentBreakpoint } =
    reference.current.swiper;

  const isBannersVariant = variant === VARIANT.Banners;
  const isBreakpoint980 = currentBreakpoint === '980';
  const isBreakpoint568 = currentBreakpoint === '568';
  const isBreakpoint1272 = currentBreakpoint === '1272';
  const isBreakpoint1300 = currentBreakpoint === '1300';
  const isMaxScreenWidth = innerWidth > MAX_SCREEN_SIZE;

  if (
    isBannersVariant &&
    (isBreakpoint980 || isBreakpoint568 || isBreakpoint1272)
  ) {
    const offsetValue = 24;
    if (activeIndex === 0 || activeIndex === slides.length - 1) {
      params.slidesOffsetBefore = offsetValue;
      params.slidesOffsetAfter = offsetValue;
    } else {
      params.slidesOffsetBefore = 0;
      params.slidesOffsetAfter = 0;
    }
  } else if (isBreakpoint1300 && isMaxScreenWidth) {
    const newOffset = (innerWidth - MAX_SCREEN_SIZE) / 2 + 24;
    params.slidesOffsetBefore = newOffset;
    params.slidesOffsetAfter = newOffset;
  }

  reference.current.swiper.update();
};

export const updateSwiperOffsets = (
  reference: CarouselReference,
  MAX_SCREEN_SIZE: number,
) => {
  if (!reference.current?.swiper) return;
  const { innerWidth } = window;
  const isSmallUp = innerWidth > 568;
  const isMaxScreenWidth = innerWidth > MAX_SCREEN_SIZE;
  let newOffset = 16;
  if (isSmallUp && !isMaxScreenWidth) {
    newOffset = 24;
  }
  if (isMaxScreenWidth) {
    newOffset = (innerWidth - MAX_SCREEN_SIZE - 16) / 2 + 24;
  }
  const Swiper = reference.current.swiper;

  Swiper.params.slidesOffsetBefore = newOffset;
  Swiper.params.slidesOffsetAfter = newOffset;
  Swiper.update();
};
