import { useCallback, useRef } from 'react';

const useCarouselNavigation = () => {
  const reference = useRef<any>(null);

  const handlePrevious = useCallback(() => {
    if (!reference.current?.swiper) return;

    if (reference.current.swiper.progress === 0)
      reference.current.swiper.slideTo(
        reference.current.swiper.slides.length - 1,
      );
    else reference.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!reference.current?.swiper) return;

    if (reference.current.swiper.progress >= 1)
      reference.current.swiper.slideTo(0);
    else reference.current.swiper.slideNext();
  }, []);

  const handlePlay = useCallback(() => {
    if (!reference.current?.swiper) return;
    reference?.current.swiper?.autoplay?.start();
  }, []);

  const handleStop = useCallback(() => {
    if (!reference.current?.swiper) return;
    reference?.current.swiper?.autoplay.stop();
  }, []);

  return {
    reference,
    handlePrevious,
    handleNext,
    handleStop,
    handlePlay,
  };
};

export default useCarouselNavigation;
