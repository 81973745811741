import useSWRInfinite from 'swr/infinite';
import { AVIOS_PREFIX } from '@/constants';
import {
  destructedFavourite,
  ToogleFeatureType,
} from './use-handle-favourites.types';
import fetcher from '../../utils/fetcher';

const API_BASE_PATH = `${AVIOS_PREFIX}/api/favourites/`;

const useHandleFavourites = () => {
  const {
    data: response,
    isLoading,
    size,
    setSize,
    isValidating,
    mutate,
  } = useSWRInfinite(
    () => `${API_BASE_PATH}`,
    url => fetcher(url),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  function isFavourite(mechanicId: number) {
    if (favouritesList) {
      return favouritesList.find(x => x.mechanic_id === mechanicId)
        ?.favourite_id;
    }
    return null;
  }

  async function toggleFavourite(
    value: boolean,
    mechanicId?: number,
    favouriteId?: number,
  ): Promise<ToogleFeatureType> {
    const result = await (value
      ? fetcher(`${API_BASE_PATH}`, 'POST', {
          mechanicId,
        })
      : fetcher(
          `${API_BASE_PATH}?mechanicId=${mechanicId}&favouriteId=${favouriteId}`,
          'DELETE',
        ));

    mutate();
    return result;
  }

  const favouritesList =
    response?.length && response[0].data?.favourites
      ? formatFavouritesList(response[0].data?.favourites)
      : [];

  return {
    favouritesList,
    isFavourite,
    toggleFavourite,
    isLoading,
    isValidating,
    size,
    setSize,
  };
};

export const formatFavouritesList = (favouritesList: destructedFavourite[]) => {
  return favouritesList
    .map(
      ({
        favourite_id,
        mechanic: { mechanic_collection_methods, mechanic_id, merchant },
      }) => ({
        favourite_id,
        mechanic_id,
        mechanic_collection_methods,
        name: merchant.name,
        slug: merchant.slug,
        logo_url: merchant.logo_url,
      }),
    )
    .filter(favourite => {
      return favourite.mechanic_collection_methods[0];
    });
};

export default useHandleFavourites;
